<template>
  <div class="container">
    <img src="../assets/img/contact_title.png" />
    <table width="920" border="0" cellspacing="0" cellpadding="0" class="phone_rowtable" align="center">
      <tbody>
        <tr>
          <th>전화영어</th>
          <td>02-518-0036</td>
          <th>글로벌교육</th>
          <td>02-518-5467</td>
          <th>교수운영</th>
          <td>02-518-2313</td>
          <th>전략마케팅</th>
          <td>02-518-8229</td>
          <th>경영기획</th>
          <td>02-518-5468</td>
        </tr>
        <tr>
          <th>글로벌리티<br />&amp;통번역</th>
          <td>02-518-5486</td>
          <th>이러닝사업부<br />/미디어랩</th>
          <td>02-518-2603</td>
          <th>솔루션<br />개발연구소</th>
          <td>02-518-0587</td>
          <th>B2C통합서비스<br />사업부</th>
          <td>02-518-0133</td>
          <th></th>
          <td></td>
        </tr>
        <tr>
          <th>부산센터</th>
          <td>051-853-5467</td>
          <th>대전센터</th>
          <td>042-472-8149</td>
          <th>천안센터</th>
          <td>041-566-8149</td>
          <th>나주-호남센터</th>
          <td>061-331-2282</td>
          <th>대련센터</th>
          <td>5372</td>
        </tr>
        <tr>
          <th>e-post센터</th>
          <td>4001</td>
          <th>밝음 생애센터</th>
          <td>02-547-1113</td>
          <th>밝음 심리센터</th>
          <td>02-516-9060</td>
          <th></th>
          <td></td>
          <th></th>
          <td></td>
        </tr>
      </tbody>
    </table>

    <div class="contact_list_bg">
      <div class="contact_list">
        <div class="contact" style="padding-right: 20px" v-for="(sector, i) in emp.list" :key="i">
          <div class="box">
            <div class="group_a">{{ sector.deptname }}</div>
            <div class="group_list">
              <!-- 부문 -->
              <div class="list" v-for="(sectorEmpList, i) in sector.empList" :key="i">
                <!-- 부문에만 소속 리스트-->
                <div class="name" @mouseover="sectorEmpList.isShowImg = i" @mouseleave="sectorEmpList.isShowImg = 999">
                  {{ sectorEmpList.name }}
                  <div v-if="sectorEmpList.isShowImg == i && sectorEmpList.photoimg != ''" class="img-over">
                    <img class="profile-img" :src="sectorEmpList.photoimg" />
                  </div>
                </div>
                <div class="extension_number">{{ sectorEmpList.mp3code }}</div>
                <div class="phone_number">{{ sectorEmpList.phone }}</div>
              </div>
            </div>
            <div v-if="sector.has_children == 'Y'">
              <!-- 부문하위 -->
              <div v-for="(office, i) in sector.children" :key="i">
                <!-- 본부 or 부문-팀 -->
                <div class="group_b" v-if="office.member_cnt > 0">
                  {{ office.deptname }}
                </div>
                <div class="group_list">
                  <div class="list" v-for="(officeEmpList, i) in office.empList" :key="i">
                    <!-- 부문-본부에만 소속 리스트-->
                    <div class="name" @mouseover="officeEmpList.isShowImg = i" @mouseleave="officeEmpList.isShowImg = 999">
                      {{ officeEmpList.name }}
                      <div v-if="officeEmpList.isShowImg == i && officeEmpList.photoimg != ''" class="img-over">
                        <img class="profile-img" :src="officeEmpList.photoimg" />
                      </div>
                    </div>
                    <div class="extension_number">
                      {{ officeEmpList.mp3code }}
                    </div>
                    <div class="phone_number">{{ officeEmpList.phone }}</div>
                  </div>
                  <div v-if="office.has_children == 'Y'">
                    <!-- 본부-팀 -->
                    <div v-for="(team, i) in office.children" :key="i">
                      <div class="team" v-if="team.has_member == 'Y'">
                        {{ team.deptname }}
                      </div>
                      <div class="list" v-for="(teamEmpList, i) in team.empList" :key="i">
                        <!-- 팀소속 리스트-->
                        <div class="name" @mouseover="teamEmpList.isShowImg = i" @mouseleave="teamEmpList.isShowImg = 999">
                          {{ teamEmpList.name }}
                          <div v-if="teamEmpList.isShowImg == i && teamEmpList.photoimg != ''" class="img-over">
                            <img class="profile-img" :src="teamEmpList.photoimg" />
                          </div>
                        </div>
                        <div class="extension_number">
                          {{ teamEmpList.mp3code }}
                        </div>
                        <div class="phone_number">{{ teamEmpList.phone }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="i == 2">
            <div class="box" v-for="(sector, i) in emp.listSubLast" :key="i">
              <div class="group_a">{{ sector.deptname }}</div>
              <div class="group_list">
                <!-- 부문 -->
                <div class="list" v-for="(sectorEmpList, i) in sector.empList" :key="i">
                  <!-- 부문에만 소속 리스트-->
                  <div class="name" @mouseover="sectorEmpList.isShowImg = i" @mouseleave="sectorEmpList.isShowImg = 999">
                    {{ sectorEmpList.name }}
                    <div v-if="sectorEmpList.isShowImg == i && sectorEmpList.photoimg != ''" class="img-over">
                      <img class="profile-img" :src="sectorEmpList.photoimg" />
                    </div>
                  </div>
                  <div class="extension_number">
                    {{ sectorEmpList.mp3code }}
                  </div>
                  <div class="phone_number">{{ sectorEmpList.phone }}</div>
                </div>
              </div>
              <div v-if="sector.has_children == 'Y'">
                <!-- 부문하위 -->
                <div v-for="(office, i) in sector.children" :key="i">
                  <!-- 본부 or 부문-팀 -->
                  <div class="group_b" v-if="office.member_cnt > 0">
                    {{ office.deptname }}
                  </div>
                  <div class="group_list">
                    <div class="list" v-for="(officeEmpList, i) in office.empList" :key="i">
                      <!-- 부문-본부에만 소속 리스트-->
                      <div class="name" @mouseover="officeEmpList.isShowImg = i" @mouseleave="officeEmpList.isShowImg = 999">
                        {{ officeEmpList.name }}
                        <div v-if="officeEmpList.isShowImg == i && officeEmpList.photoimg != ''" class="img-over">
                          <img class="profile-img" :src="officeEmpList.photoimg" />
                        </div>
                      </div>
                      <div class="extension_number">
                        {{ officeEmpList.mp3code }}
                      </div>
                      <div class="phone_number">{{ officeEmpList.phone }}</div>
                    </div>
                    <div v-if="office.has_children == 'Y'">
                      <!-- 본부-팀 -->
                      <div v-for="(team, i) in office.children" :key="i">
                        <div class="team" v-if="team.has_member == 'Y'">
                          {{ team.deptname }}
                        </div>
                        <div class="list" v-for="(teamEmpList, i) in team.empList" :key="i">
                          <!-- 팀소속 리스트-->
                          <div class="name" @mouseover="teamEmpList.isShowImg = i" @mouseleave="teamEmpList.isShowImg = 999">
                            {{ teamEmpList.name }}
                            <div v-if="teamEmpList.isShowImg == i && teamEmpList.photoimg != ''" class="img-over">
                              <img class="profile-img" :src="teamEmpList.photoimg" />
                            </div>
                          </div>
                          <div class="extension_number">
                            {{ teamEmpList.mp3code }}
                          </div>
                          <div class="phone_number">
                            {{ teamEmpList.phone }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <table width="920" border="0" cellspacing="0" cellpadding="0" class="address_table" align="center">
      <tbody>
        <tr>
          <th rowspan="3">서울</th>
          <td class="sub-title">한남</td>
          <td>서울 용산구 한남동 이태원로 268-20 (한남캠퍼스)</td>
        </tr>
        <tr>
          <td rowspan="2" class="sub-title">강남</td>
          <td>솔루션즈 - 서울시 서초구 강남대로 479, 신논현타워 6F</td>
        </tr>
        <tr>
          <td>밝음 - 서울시 서초구 사평대로 371, 3층</td>
        </tr>

        <tr>
          <th rowspan="2">경기도</th>
          <td class="sub-title">이천센터</td>
          <td>경기도 이천시 경충대로 2091, SKHU 4층 어학강사실</td>
        </tr>
        <tr>
          <td class="sub-title">동탄거점오피스</td>
          <td>경기 화성시 동탄영천로 131(영천동) 코너원스마트타워 712호</td>
        </tr>

        <tr>
          <th>충남</th>
          <td class="sub-title">천안센터</td>
          <td>충청남도 천안시 서북구 불당25로 178, 에이블타워 206호</td>
        </tr>

        <tr>
          <th>대전</th>
          <td class="sub-title">대전센터</td>
          <td>대전광역시 서구 둔산중로 74, 501호 (둔산동, 인곡타워)</td>
        </tr>

        <tr>
          <th>부산</th>
          <td class="sub-title">부산센터</td>
          <td>부산광역시 해운대구 센텀중앙로 78, 센텀그린타워 1108호</td>
        </tr>

        <tr>
          <th>경상도</th>
          <td class="sub-title">사천센터</td>
          <td>경상남도 진주시 충무공동 69-3 윙스타워 A동 1506호 캐럿에비에이션</td>
        </tr>

        <tr>
          <th>전라도</th>
          <td class="sub-title">나주센터</td>
          <td>전라남도 나주시 배멧2길 25, 스페이스코웍 318호</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
// @ is an alias to /src
import { onMounted, reactive } from "vue";
import { useToast } from "vue-toastification";
import axios from "@/plugins/axios.js";

export default {
  components: {},
  setup() {
    const toast = useToast();
    const emp = reactive({
      uid: "",
      upw: "",
      check: "N",
      list: [],
      listSubLast: [],
      doSearch: () => {
        axios.post("/rest/mypims/getEmpNumber").then((res) => {
          if (res.data.err == 0) {
            console.log(res.data);
            emp.list = res.data.listFirst;
            emp.listSubLast = res.data.listSubLast;
          } else {
            if (res.data.err_msg) toast.error(res.data.err_msg);
          }
        });
      },
    });
    onMounted(() => {
      // Mounted
      emp.doSearch();
    });

    return { emp };
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 980px;
  margin: 0 auto;
}
img {
  margin: 10px 0 0 17px;
}
.profile-img {
  margin: 0 0 0 0;
  width: 200px;
  height: 200px;
}
.phone_rowtable {
  font-weight: 400;
  font-size: 12.5px;
  font-family: "Noto Sans KR", sans-serif;
  color: #151515;
  margin: 14px 30px 21px;
  th {
    background-color: #edf0f5;
    padding: 8px 0;
    border-top: 1px solid #dfe2e9;
    border-bottom: 1px solid #dfe2e9;
  }
  td {
    text-align: center;
    border-top: 1px solid #dfe2e9;
    border-bottom: 1px solid #dfe2e9;
    font-weight: 300;
  }
}

.address_table {
  margin: 14px 30px 21px;
  th {
    background-color: #edf0f5;
    border-top: 1px solid #dfe2e9;
    border-bottom: 1px solid #dfe2e9;
    vertical-align: middle;
    font-weight: bold;
  }
  td {
    padding: 10px;
    border-top: 1px solid #dfe2e9;
    border-bottom: 1px solid #dfe2e9;
    font-weight: 300;
    vertical-align: middle;
  }
  .sub-title {
    border-right: 1px solid #dfe2e9;
  }
}

.img-over {
  position: absolute;
  z-index: 555;
  min-width: 220px;
  max-width: 220px;
  overflow: hidden;
  min-height: 220px;
  min-width: 220px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  padding: 10px;
}
.contact_list_bg {
  background-color: #f3f6fb;
  width: 980px;
  padding: 0 0 26px;

  .contact_list {
    padding: 0 30px;
    display: inline-flex;
    flex-flow: row wrap;
    align-content: flex-start;
    flex-direction: row;
    //           float: left;
    .contact_list.stretch {
      align-content: flex-start;
    }
    .contact {
      padding-right: 20px;
    }
    .group_a {
      width: 277px;
      color: #ff662a;
      border: 1px solid #ff662a;
      background-color: #fff;
      padding: 7px 0;
      font-weight: 700;
      text-align: center;
      margin: 30px 0 8px 0;
    }
    .group_b {
      font-weight: 700;
      font-size: 14px;
      color: #151515;
      margin: 10px 0 10px;
    }
    .group_list {
      border: 1px solid #eeeff3;
      border-bottom: none;
      .team {
        background-color: #f97c44;
        text-align: center;
        color: #fff;
        font-size: 12px;
        font-weight: 400;
        padding: 7px 0px;
        border-bottom: 1px solid #eeeff3;
      }
      .list {
        font-weight: 400;
        color: #151515;
        background-color: #fff;
        padding: 7px 0;
        font-size: 12px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #eeeff3;
        &.bg_col {
          background-color: #fafbff;
        }
        .name {
          width: 110px;
          padding: 0 4px;
          text-align: center;
          word-break: break-all;
        }
        .extension_number {
          width: 47px;
          height: 20px;
          background-color: #ecf1f7;
          text-align: center;
          border-radius: 10px;
          color: #5d6267;
        }
        .phone_number {
          text-align: center;
          width: 112px;
        }
      }
    }
  }
  p {
    &:nth-of-type(1) {
      padding: 30px 0 5px 0;
    }
    font-weight: 400;
    padding: 0 0 5px;
    color: #151515;
    font-size: 12px;
    span {
      font-weight: 700;
    }
    margin: 0 0 0 30px;
  }
}
</style>
